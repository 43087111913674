import React from "react";
import styled, { keyframes } from "styled-components";
// import { Link } from "react-router-dom";
import { Link } from '@pancakeswap/uikit';
import Flex from "../../../components/Box/Flex";

interface Props {
  isPushed: boolean;
  isDark: boolean;
  togglePush: () => void;
  href: string;
}

const blink = keyframes`
  0%,  100% { transform: scaleY(1); } 
  50% { transform:  scaleY(0.1); } 
`;

const StyledExternalLink = styled(Link)`
  display: flex;
  align-items: center;
  .mobile-icon {
    width: 95px;
    ${({ theme }) => theme.mediaQueries.nav} {
      display: none;
    }
    margin-left: 1em;
  }
  .desktop-icon {
    width: 120px;
    display: none;
    ${({ theme }) => theme.mediaQueries.nav} {
      display: block;
    }
    margin-left: 5em;
  }
  .right-eye {
    animation-delay: 20ms;
  }
  &:hover {
    .left-eye,
    .right-eye {
      transform-origin: center 60%;
      animation-name: ${blink};
      animation-duration: 350ms;
      animation-iteration-count: 1;
    }
  }
`;

const Logo: React.FC<Props> = ({ href }) => {
  const isAbsoluteUrl = href.startsWith("http");
  const innerLogo = (
    <>
      <img src="/logo.png" alt="" className="mobile-icon"/>
      <img src="/logo.png" alt="" className="desktop-icon"/>
    </>
  );

  return (
    <Flex>
      {isAbsoluteUrl ? (
        <StyledExternalLink external href={href} aria-label="Monsta Infinite Home Page">
          {innerLogo}
        </StyledExternalLink>
      ) : (
        <StyledExternalLink external href={href} aria-label="Monsta Infinite Home Page">
          {innerLogo}
        </StyledExternalLink>
      )}
    </Flex>
  );
};

export default React.memo(Logo, (prev, next) => prev.isPushed === next.isPushed && prev.isDark === next.isDark);
