import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import throttle from "lodash/throttle";
import Flex from "../../components/Box/Flex";
import { useMatchBreakpoints } from "../../hooks";
import Logo from "./components/Logo2";
import Panel from "./components/Panel";
import { NavProps } from "./types";
import { MENU_HEIGHT } from "./config";
// import headerImage from './pngs/headerImage.png'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  background-color: rgba(0,0,0,0.0);
`;

const StyledNav = styled.nav<{ showMenu: boolean }>`
  position: fixed;
  top: ${({ showMenu }) => (showMenu ? 0 : `-${MENU_HEIGHT}px`)};
  left: 0;
  transition: top 0.2s;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 8px;
  padding-right: 16px;
  width: 100%;
  height: ${MENU_HEIGHT}px;
  background: url(https://monstainfinite.com/wp-content/uploads/2021/08/navbar-bg-2-scaled.webp);
  background-color: rgba(0,0,0,0.0);
  background-repeat: no-repeat !important;
  background-size: cover;
  background-position: top center;
  z-index: 20;
  transform: translate3d(0, 0, 0);
  background-size: auto;
  ${({ theme }) => theme.mediaQueries.sm} {
    background-size: 100% 103%;
  }
  ${({ theme }) => theme.mediaQueries.lg} {
    background-size: 100% 100%;
  }
`;

const ConnectButtonWrapper = styled.div`
  position: relative;
  right: 3em;
  top: -0.5em;
  ${({ theme }) => theme.mediaQueries.ss} {
    width: 25px;
    right: 3.5em;
    top: 0em;
  }
  ${({ theme }) => theme.mediaQueries.xxs} {
    width: 55px;
    right: 2em;
    top: 0em;
  }
  ${({ theme }) => theme.mediaQueries.xs} {
    width: auto;
    right: -0.5em;
    top: 0em;
  }
  ${({ theme }) => theme.mediaQueries.sm} {
    right: 1em;
    top: -0.5em;
  }
  ${({ theme }) => theme.mediaQueries.lg} {
    right: 5em;
    top: -0.6em;
  }
`;

const BodyWrapper = styled.div`
  position: relative;
  display: flex;
`;

const Inner = styled.div<{ isPushed: boolean; showMenu: boolean }>`
  flex-grow: 1;
  transition: margin-top 0.2s, margin-left 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transform: translate3d(0, 0, 0);
  max-width: 100%;
`;

const Menu: React.FC<NavProps> = ({
  userMenu,
  isDark,
  toggleTheme,
  langs,
  setLang,
  currentLang,
  cakePriceUsd,
  links,
  children,
}) => {
  const { isMobile, isTablet } = useMatchBreakpoints();
  const isSmallerScreen = isMobile || isTablet;
  const [isPushed, setIsPushed] = useState(!isSmallerScreen);
  const [showMenu, setShowMenu] = useState(true);
  const refPrevOffset = useRef(window.pageYOffset);

  useEffect(() => {
    const handleScroll = () => {
      const currentOffset = window.pageYOffset;
      const isBottomOfPage = window.document.body.clientHeight === currentOffset + window.innerHeight;
      const isTopOfPage = currentOffset === 0;
      // Always show the menu when user reach the top
      if (isTopOfPage) {
        setShowMenu(true);
      }
      // Avoid triggering anything at the bottom because of layout shift
      else if (!isBottomOfPage) {
        if (currentOffset < refPrevOffset.current) {
          // Has scroll up
          setShowMenu(true);
        } else {
          // Has scroll down
          setShowMenu(false);
        }
      }
      refPrevOffset.current = currentOffset;
    };
    const throttledHandleScroll = throttle(handleScroll, 200);

    window.addEventListener("scroll", throttledHandleScroll);
    return () => {
      window.removeEventListener("scroll", throttledHandleScroll);
    };
  }, []);

  return (
    <Wrapper>
      <StyledNav showMenu={showMenu}>
        <Logo
          isPushed={isPushed}
          togglePush={() => setIsPushed((prevState: boolean) => !prevState)}
          isDark={isDark}
          href="https://monstainfinite.com"
        />
        <Flex>
          <ConnectButtonWrapper>{userMenu}</ConnectButtonWrapper>
        </Flex>
      </StyledNav>
      <BodyWrapper>
        <Panel
          isPushed={isPushed}
          isMobile={isSmallerScreen}
          showMenu={showMenu}
          isDark={isDark}
          toggleTheme={toggleTheme}
          langs={langs}
          setLang={setLang}
          currentLang={currentLang}
          cakePriceUsd={cakePriceUsd}
          pushNav={setIsPushed}
          links={links}
        />
        <Inner isPushed={isPushed} showMenu={showMenu}>
          {children}
        </Inner>
      </BodyWrapper>
    </Wrapper>
  );
};

export default Menu;
