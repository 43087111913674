import React from 'react'
import styled from 'styled-components'
import { Flex, Link } from '@pancakeswap/uikit'
import { useTranslation } from 'contexts/Localization'

import MonstaLogo from '../pngs/monsta-logo.png'
import FBIcon from '../pngs/fb.png'
import TelegramIcon from '../pngs/telegram.webp'
import YoutubeIcon from '../pngs/youtube.webp'
import DiscordIcon from '../pngs/discord.webp'
import TwitterIcon from '../pngs/twitter.webp'

const Wrapper = styled(Flex)`
  z-index: 1;
  position: relative;
  height: 755px;
  width: 100%;
  align-items: center;
  flex-direction: column;
  padding: 8px;
  justify-content: flex-end;
  background: url('/images/home/background/footer-cloud.webp') no-repeat;
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
  margin-top: calc(-70vh);
  ${({ theme }) => theme.mediaQueries.ss} {
    height: 400px;
    margin-top: calc(-34vh);
    background-size: cover;
  }
  ${({ theme }) => theme.mediaQueries.xxs} {
    height: 450px;
    margin-top: calc(-34vh);
    background-size: cover;
  }
  ${({ theme }) => theme.mediaQueries.xs} {
    height: 450px;
    margin-top: calc(-30vh);
    background-size: cover;
  }
  ${({ theme }) => theme.mediaQueries.sm} {
    height: 550px;
    margin-top: calc(-35vh);
    background-size: cover;
  }
  ${({ theme }) => theme.mediaQueries.md} {
    height: 905px;
    margin-top: calc(-40vh);
    background-size: cover;
  }
  ${({ theme }) => theme.mediaQueries.lg} {
    height: 800px;
    margin-top: calc(-40vh);
    background-size: cover;
  }
  ${({ theme }) => theme.mediaQueries.xxl || theme.mediaQueries.xl} {
    height: 1000px;
    margin-top: calc(-40vh);
    background-size: cover;
  }
`

const ContentContainer = styled(Flex)`
  padding: 8px;
  width: 100%;
  flex-direction: column;
  align-content: center;
  align-items: center;
`

const ContactContainer = styled(Flex)`
  flex-direction: row;
  width: 100%;
  margin-bottom: 60px;
  ${({ theme }) => theme.mediaQueries.ss} {
    margin-bottom: 10px;
    flex-direction: column;
  }
  ${({ theme }) => theme.mediaQueries.xxs} {
    margin-bottom: 10px;
    flex-direction: column;
  }
  ${({ theme }) => theme.mediaQueries.xs} {
    margin-bottom: 10px;
    flex-direction: column;
  }
  ${({ theme }) => theme.mediaQueries.md} {
    margin-bottom: 60px;
    flex-direction: row;
  }
`

const LinkWrapper = styled(Flex)`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-bottom: 8px;
`

const SubscribeWrapper = styled.div`
  width: 100%;
  align-content: center;
  align-items: center;
  justify-content: center;
  text-align: center;
  ${({ theme }) => theme.mediaQueries.lg} {
    position: relative;
    bottom: 1em;
    text-align: left;
  }
  ${({ theme }) => theme.mediaQueries.xl} {
    position: relative;
    bottom: 1em;
    text-align: left;
  }
  ${({ theme }) => theme.mediaQueries.xxl} {
    position: relative;
    bottom: 1em;
    text-align: left;
  }
`

const NavLinkWrapper = styled(LinkWrapper)`
  position: relative;
  right: 5.5em;
  margin-top: 70px;
  flex-direction: column;
  justify-content: center;
  ${({ theme }) => theme.mediaQueries.ss} {
    right: 0em;
    flex-direction: row;
    margin-top: 10px;
  }
  ${({ theme }) => theme.mediaQueries.xxs} {
    right: 0em;
    flex-direction: row;
    margin-top: 10px;
  }
  ${({ theme }) => theme.mediaQueries.xs} {
    right: 0em;
    flex-direction: row;
    margin-top: 10px;
  }
  ${({ theme }) => theme.mediaQueries.sm} {
    right: 0em;
    margin-top: 10px;
    flex-direction: row;
  }
  ${({ theme }) => theme.mediaQueries.md} {
    right: 2em;
    margin-top: 30px;
    flex-direction: row;
  }
  ${({ theme }) => theme.mediaQueries.lg} {
    right: 2em;
    margin-top: 30px;
    flex-direction: row;
  }
  ${({ theme }) => theme.mediaQueries.xl} {
    right: 5.5em;
    margin-top: 70px;
    flex-direction: row;
  }
  ${({ theme }) => theme.mediaQueries.xxl} {
    right: 2.8em;
    margin-top: 70px;
    flex-direction: row;
  }

`

const NavLink = styled(Link)`
  font-size: 14pt;
  color: #ffffff;
  background: #88a6d8;
  border-radius: 40px;
  border: 4px #26716d solid;
  padding: 5px 25px;
  margin-left: 10px;
  margin-right: 10px;
  ${({ theme }) => theme.mediaQueries.ss} {
    font-size: 4pt;
    padding: 5px 20px;
    margin-left: 1px;
    margin-right: 1px;
  }
  ${({ theme }) => theme.mediaQueries.xxs} {
    font-size: 6pt;
    padding: 5px 25px;
    margin-left: 2px;
    margin-right: 2px;
  }
  ${({ theme }) => theme.mediaQueries.xs} {
    font-size: 6pt;
    padding: 5px 25px;
    margin-left: 2px;
    margin-right: 2px;
  }
  ${({ theme }) => theme.mediaQueries.sm} {
    font-size: 10pt;
    padding: 5px 25px;
    margin-left: 2px;
    margin-right: 2px;
  }
  ${({ theme }) => theme.mediaQueries.md} {
    font-size: 12pt;
    padding: 5px 25px;
    margin-left: 2px;
    margin-right: 2px;
  }
  ${({ theme }) => theme.mediaQueries.lg} {
    font-size: 12pt;
    padding: 5px 25px;
    margin-left: 4px;
    margin-right: 4px;
  }
  ${({ theme }) => theme.mediaQueries.xl} {
    font-size: 16pt;
    padding: 5px 25px;
    margin-left: 10px;
    margin-right: 10px;
  }
  ${({ theme }) => theme.mediaQueries.xxl} {
    padding: 5px 25px;
    margin-left: 10px;
    margin-right: 10px;
  }
`

const MonstaLogoImg = styled.img`
  width: 27vw;
`

const ResponsiveSocialImg = styled.img`
  margin: 8px;
  width: 30px;
  ${({ theme }) => theme.mediaQueries.ss} {
    margin: 4px;
    width: 20px;
  }
  ${({ theme }) => theme.mediaQueries.xxs} {
    margin: 4px;
    width: 20px;
  }
  ${({ theme }) => theme.mediaQueries.xs} {
    margin: 4px;
    width: 20px;
  }
  ${({ theme }) => theme.mediaQueries.sm} {
    margin: 4px;
    width: 20px;
  }
  ${({ theme }) => theme.mediaQueries.md} {
    margin: 4px;
    width: 60px;
  }
  ${({ theme }) => theme.mediaQueries.lg} {
    margin: 4px;
    width: 50px;
  }
  ${({ theme }) => theme.mediaQueries.xl} {
    width: 70px;
    margin: 4px;
  }
`

const SwapFooter = () => {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <ContentContainer>
        <ContactContainer>
          <LinkWrapper>
            <MonstaLogoImg src={MonstaLogo} alt="monsta-logo" />
          </LinkWrapper>
          <NavLinkWrapper>
            <NavLink href="/">{t('HOME')}</NavLink>
            <NavLink color="#090000" external href="https://monstainfinite.com">
              {t('OFFICIAL WEBSITE')}
            </NavLink>
            <NavLink color="#090000" external href="https://docs.monstainfinite.com/">
              {t('WHITEPAPER')}
            </NavLink>
          </NavLinkWrapper>
        </ContactContainer>
        <ContactContainer>
          <LinkWrapper>
            <Link external href="https://t.me/monstainfinite">
              <ResponsiveSocialImg src={TelegramIcon} alt="telegram-icon" />
            </Link>
            <Link external href="https://www.facebook.com/monstainfinite">
              <ResponsiveSocialImg src={FBIcon} alt="fb-icon" />
            </Link>
            <Link external href="https://bit.ly/monstainfiniteyoutube">
              <ResponsiveSocialImg src={YoutubeIcon} alt="youtube-icon" />
            </Link>
            <Link external href="https://discord.gg/monsta">
              <ResponsiveSocialImg src={DiscordIcon} alt="discord-icon" />
            </Link>
            <Link external href="https://twitter.com/monsta_infinite">
              <ResponsiveSocialImg src={TwitterIcon} alt="twitter-icon" />
            </Link>
          </LinkWrapper>
          <SubscribeWrapper>
            <></>
          </SubscribeWrapper>
        </ContactContainer>
      </ContentContainer>
    </Wrapper>
  )
}

export default SwapFooter
