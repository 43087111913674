import { useMemo } from 'react'
import { Contract } from '@ethersproject/contracts'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useGasPrice } from 'state/user/hooks'
import { callWithEstimateGas } from 'utils/calls'
import { useTransactionAdder } from '../state/transactions/hooks'
import { getInceptionMonstaMediatorContract } from '../utils/contractHelpers'

export enum SwapCallbackState {
  INVALID,
  LOADING,
  VALID,
}

// returns a function that will execute a transaction, if the parameters are all valid
export function useIMTCallback(destinationAddress: string, numToken: string): {
  state: SwapCallbackState
  callback: null | (() => Promise<string>)
  error: string | null
} {
  const { account, chainId, library } = useActiveWeb3React()
  const gasPrice = useGasPrice()
  const addTransaction = useTransactionAdder()

  return useMemo(() => {
    if (!numToken || !library || !account || !chainId) {
      return { state: SwapCallbackState.INVALID, callback: null, error: 'Missing dependencies' }
    }
    return {
      state: SwapCallbackState.VALID,
      callback: async function onSwap(): Promise<string> {
        const monstaInceptionContract: Contract | null = getInceptionMonstaMediatorContract((library.getSigner()))
        if (!monstaInceptionContract) {
            return 'no contract'
        }
  
        return callWithEstimateGas(monstaInceptionContract, 'relayTokens', [destinationAddress.replace('monsta:', '0x'), numToken], {
            gasPrice,
        },{ from: account, value: 0})
          .then((response: any) => {
            addTransaction(response, {
              summary: `Bridge IMT `,
            })
            return response.hash
          })
          .catch((error: any) => {
            // if the user rejected the tx, pass this along
            if (error?.code === 4001) {
              throw new Error('Transaction rejected.')
            } else {
              // otherwise, the error was unexpected and we need to convey that
              if (error?.code === -32603){
                throw new Error(`Bridge IMT failed: ${error.data?.message}`)
              }
              throw new Error(`Bridge IMT failed: ${error.message}`)
            }
          })
      },

      error: null,
    }
  }, [library, account, chainId, numToken, destinationAddress, addTransaction, gasPrice])
}
