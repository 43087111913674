import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 16 16" {...props}>
      <circle cx="8" cy="8" fill="#09806e" r="7.91" />
      <path
        d="m9.19 6.85 2.53-2.53.56-.56h-.8-1.44-.13l-.1.1-1.81 1.81-1.81-1.82-.1-.1h-.14-1.44-.8l.56.56 2.53 2.53-2.52 2.54-.23.24.23.23 3.48 3.49.24.23.23-.23 3.49-3.49.23-.23-.23-.23zm.39 2.77-1.58 1.58-1.58-1.58 1.58-1.58z"
        fill="#fefefd"
      />
    </Svg>
  )
}

export default Icon
