import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import { Trade, CurrencyAmount } from '@pancakeswap/sdk'
import { Flex, Box, useModal } from '@pancakeswap/uikit'
import BridgeLinkFooter from 'components/BridgeLinkFooter'
import { useTranslation } from 'contexts/Localization'
import { useGetBnbBalance } from 'hooks/useTokenBalance'
import { BigNumber as BN } from 'bignumber.js'
import { AutoColumn } from '../../components/Layout/Column'
import { SwapCallbackError, Wrapper } from './components/styleds'
import { AppHeader, AppBody } from '../../components/App'
import ConnectWalletButton from '../../components/ConnectWalletButton'
import useActiveWeb3React from '../../hooks/useActiveWeb3React'
import useApproveConfirmTransaction from '../../hooks/useApproveConfirmTransaction'
import { useIMTCallback } from '../../hooks/useIMTCallback'
import { useCallWithGasPrice } from '../../hooks/useCallWithGasPrice'
import { useMediatorAllowance } from '../../hooks/useMonstaBarterData'
import { useDerivedSwapInfo } from '../../state/swap/hooks'
import TransactionsModal from '../../components/App/Transactions/TransactionsModal'
import SwapFooter from './components/SwapFooter'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
import { Field } from '../../state/swap/actions'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import ApproveConfirmButtons from './components/ApproveConfirmButtons'
import { useInceptionMonstaContract, useInceptionMonstaMediatorContract } from '../../hooks/useContract'

const PageWrapper = styled(Flex)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-size: 100%;
  background-color: #0d483f;
`

const BuySection = styled(Flex)`
  z-index: 1;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  background-color: #0d483f;
  background: url('/images/home/background/bridge-bg@2x.png') no-repeat;
  background-position: top;
  background-size: 100%;
  padding-top: 5vh;
  padding-bottom: calc(23vh);

  ${({ theme }) => theme.mediaQueries.sm || theme.mediaQueries.xs} {
    padding-bottom: calc(23vh);
  }

  ${({ theme }) => theme.mediaQueries.md} {
    padding-bottom: calc(41vh);
  }

  ${({ theme }) => theme.mediaQueries.lg || theme.mediaQueries.xxl} {
    padding-bottom: calc(60vh);
  }
`
const BuyWrapper = styled.div`
  max-width: 436px;
  width: 100%;
  height: 100%;
  // background: url('/images/home/background/buy-bg.webp') no-repeat;
  margin-top: 55vw;
  background: url('/images/home/background/bg-mobile.png') no-repeat;

  ${({ theme }) => theme.mediaQueries.ss} {
    background: url('/images/home/background/buy-bg.webp') no-repeat;
    background-size: 100% 101%;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    background: url('/images/home/background/buy-bg.webp') no-repeat;
    background-size: 100% 103%;
  }

  ${({ theme }) => theme.mediaQueries.lg || theme.mediaQueries.xxl} {
    background: url('/images/home/background/buy-bg.webp') no-repeat;
    background-size: contain;
  }
  background-size: contain;
`
const MonstaOwned = styled.div`
  display: flex;
  flex-flow: row nowrap;
  position: relative;
  height: 100px;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.colors.input};
  box-shadow: ${({ theme }) => theme.shadows.inset};

  ${({ theme }) => theme.mediaQueries.ss} {
    height: 85px;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    height: 100px;
  }
  `
const ImageWrapper = styled.div`
  height: 45px;
  width: 45px;
  margin-top: 30px;
  margin-left: 40px;
  background-color: ${({ theme }) => theme.colors.input};

  ${({ theme }) => theme.mediaQueries.ss} {
    height: 50px;
    width: 50px;
  }
  ${({ theme }) => theme.mediaQueries.xxs} {
    height: 50px;
    width: 50px;
    margin-top: 20px;
    margin-left: 30px;
  }
  ${({ theme }) => theme.mediaQueries.xs} {
    height: 45px;
    width: 45px;
    margin-top: 20px;
    margin-left: 26px;
  }
  ${({ theme }) => theme.mediaQueries.sm} {
    height: 45px;
    width: 45px;
    margin-top: 20px;
    margin-left: 35px;
  }
  ${({ theme }) => theme.mediaQueries.lg} {
    height: 45px;
    width: 45px;
    margin-top: 30px;
    margin-left: 40px;
  }
  `
  const MonstaOwnedLabel = styled.label`
  color: #00A651;
  margin-top: 45px;
  margin-left: 20px;

  ${({ theme }) => theme.mediaQueries.ss} {
    margin-top: 18px;
  }
  ${({ theme }) => theme.mediaQueries.xxs} {
    margin-top: 26px;
  }
  ${({ theme }) => theme.mediaQueries.xs} {
    margin-top: 35px;
    margin-left: 15px;
  }
  ${({ theme }) => theme.mediaQueries.sm} {
    margin-top: 35px;
    margin-left: 20px;
  }
  ${({ theme }) => theme.mediaQueries.lg} {
    margin-top: 43px;
    margin-left: 20px;
  }
`
const NoMonstaOwned = styled.div`
  display: flex;
  flex-flow: row nowrap;
  position: relative;
  height: 100px;
  border-radius: 16px;
  background-color: #4C2222;
  box-shadow: ${({ theme }) => theme.shadows.inset};

  ${({ theme }) => theme.mediaQueries.ss} {
    height: 75px;
  }
  ${({ theme }) => theme.mediaQueries.xxs} {
    height: 85px;
  }
  ${({ theme }) => theme.mediaQueries.xs} {
    height: 85px;
  }
  ${({ theme }) => theme.mediaQueries.lg} {
    height: 100px;
  }
  `
const NoMonstaImageWrapper = styled.div`
  height: 45px;
  width: 45px;
  margin-top: 30px;
  margin-left: 35px;
  background-color: #4C2222;

  ${({ theme }) => theme.mediaQueries.ss} {
    margin-left: 13px;
    margin-top: 22px;
  }
  ${({ theme }) => theme.mediaQueries.xxs} {
    margin-left: 30px;
    margin-top: 25px;
  }
  ${({ theme }) => theme.mediaQueries.xs} {
    margin-left: 20px;
    margin-top: 20px;
  }
  ${({ theme }) => theme.mediaQueries.sm} {
    margin-left: 30px;
    margin-top: 20px;
  }
  ${({ theme }) => theme.mediaQueries.lg} {
    margin-left: 35px;
    margin-top: 30px;
  }
  `
const NoMonstaOwnedLabel = styled.label`
  color: #FF5A6F;
  margin-top: 45px;
  margin-left: 20px;

  ${({ theme }) => theme.mediaQueries.ss} {
    margin-top: 20px;
  }
  ${({ theme }) => theme.mediaQueries.xxs} {
    margin-top: 25px;
  }
  ${({ theme }) => theme.mediaQueries.xs} {
    margin-top: 30px;
  }
  ${({ theme }) => theme.mediaQueries.sm} {
    margin-top: 35px;
  }
  ${({ theme }) => theme.mediaQueries.lg} {
    margin-top: 45px;
  }
`

export default function Swap() {
  const { t } = useTranslation()
  const { account } = useActiveWeb3React()
  const bnbBalance = useGetBnbBalance()
  const [isStarted] = useState<boolean>(process.env.REACT_APP_STARTED === 'true')
  const [onPresentTransactionsModal] = useModal(<TransactionsModal />)
  const [destinationAddress, setDestinationAddress] = useState<string>('')
  const [isValidAddress, setIsValidAddress] = useState<boolean>(false)
  const [inputAmount, setInputAmount] = useState<string>('1')
  const IMTContract = useInceptionMonstaContract()
  const IMTMediatorContract = useInceptionMonstaMediatorContract()
  const mediatorAllowance = useMediatorAllowance(account)
  const { callWithGasPrice } = useCallWithGasPrice()
  const [{ tradeToConfirm, attemptingTxn, swapErrorMessage, swapSuccess }, setSwapState] = useState<{
    tradeToConfirm: Trade | undefined
    attemptingTxn: boolean
    swapErrorMessage: string | undefined
    txHash: string | undefined
    swapSuccess: boolean
  }>({
    tradeToConfirm: undefined,
    attemptingTxn: false,
    swapErrorMessage: undefined,
    txHash: undefined,
    swapSuccess: false,
  })

  const { isApproving, handleApprove, handleConfirm } = useApproveConfirmTransaction({
    onRequiresApproval: async () => {
      return null
    },
    onApprove: () => {
      return callWithGasPrice(IMTContract, 'approve', [IMTMediatorContract.address, inputAmount])
    },
    onConfirm: () => {
      return null
    },
    onSuccess: () => null,
  })

  // TODO Input amount cannot more than balance
  const isBalanceSufficient = useMemo(() => {
    if (bnbBalance.balance) {
      return bnbBalance.balance.gte(new BN(0))
    }
    return false
  }, [bnbBalance])

  // the callback to execute the swap
  const { callback: swapCallback, error: swapCallbackError } = useIMTCallback(destinationAddress, inputAmount)

  const handleSwap = useCallback(() => {
    if (!swapCallback) {
      return
    }
    setSwapState({
      attemptingTxn: true,
      tradeToConfirm,
      swapErrorMessage: undefined,
      txHash: undefined,
      swapSuccess: false,
    })
    swapCallback()
      .then((hash) => {
        setSwapState({
          attemptingTxn: false,
          tradeToConfirm,
          swapErrorMessage: undefined,
          txHash: hash,
          swapSuccess: true,
        })
        onPresentTransactionsModal()
      })
      .catch((error) => {
        setSwapState({
          attemptingTxn: false,
          tradeToConfirm,
          swapErrorMessage: error.message,
          txHash: undefined,
          swapSuccess: false,
        })
      })
  }, [swapCallback, tradeToConfirm, onPresentTransactionsModal])

  // swap
  const { currencyBalances, currencies } = useDerivedSwapInfo()
  const maxAmountInput: CurrencyAmount | undefined = maxAmountSpend(currencyBalances[Field.INPUT])

  const handleMaxInput = useCallback(() => {
    if (maxAmountInput) setInputAmount(maxAmountInput.toExact())
  }, [maxAmountInput])

  const handleDestinationInput = (value) => {
    setDestinationAddress(value)
    if (/^monsta:([0-9a-fA-F]{40})$/.test(value)) setIsValidAddress(true)
    else setIsValidAddress(false)
  }

  const monstaOwned = currencyBalances[Field.INPUT] && parseInt(currencyBalances[Field.INPUT].toExact()) > 0

  return (
    <>
      <PageWrapper>
        <BuySection>
          <AppBody>
            <BuyWrapper>
              <AppHeader title={t('Bridge Inception Monsta')} subtitle={t('')} />
              <Wrapper id="buy-now">
                <Flex pt="24px" flexDirection="column">
                  <AutoColumn gap="md">
                    {!monstaOwned ? (
                      <NoMonstaOwned>
                        <NoMonstaImageWrapper>
                          <img src="/NoMonsta.png" alt=""/>
                        </NoMonstaImageWrapper>
                        <NoMonstaOwnedLabel>{t('NO INCEPTION MONSTA OWNED')}</NoMonstaOwnedLabel>
                      </NoMonstaOwned>
                    ): (
                      <MonstaOwned>
                        <ImageWrapper>
                          <img src="/correct.png" alt=""/>
                        </ImageWrapper>
                        <MonstaOwnedLabel>{t('OWNED INCEPTION MONSTA')}</MonstaOwnedLabel>
                      </MonstaOwned>
                    )
                    }
                    <AutoColumn justify="space-between" />
                    <CurrencyInputPanel
                      value={destinationAddress}
                      onUserInput={handleDestinationInput}
                      label={t('Monsta Wallet Address')}
                      showMaxButton={false}
                      currency={currencies[Field.OUTPUT]}
                      onCurrencySelect={null}
                      disableCurrencySelect
                      otherCurrency={currencies[Field.INPUT]}
                      id="swap-currency-output"
                      hideBalance
                      placeholder="monsta:61Ee3c812CffB9e5E5e259931c913DcbAda021B5"
                    />
                  </AutoColumn>
                </Flex>
                <Box mt="20px" pb="100px">
                  {!account ? (
                    <ConnectWalletButton width="100%" />
                  ) : (
                    <ApproveConfirmButtons
                      isApproveDisabled={
                        !isValidAddress ||
                        !(currencyBalances[Field.INPUT] && parseInt(currencyBalances[Field.INPUT].toExact()) > 0)
                      }
                      isApproving={isApproving}
                      isConfirmDisabled={
                        !isValidAddress ||
                        !isBalanceSufficient ||
                        !(currencyBalances[Field.INPUT] && parseInt(currencyBalances[Field.INPUT].toExact()) > 0) ||
                        (mediatorAllowance && mediatorAllowance.toNumber() < 0)
                      } // !isApproved || isConfirmed
                      isConfirming={attemptingTxn}
                      onApprove={handleApprove}
                      onConfirm={handleSwap}
                      confirmLabel={
                        !isValidAddress
                          ? 'Invalid Address'
                          : !isBalanceSufficient
                          ? 'Insufficient Balance'
                          : !(currencyBalances[Field.INPUT] && parseInt(currencyBalances[Field.INPUT].toExact()) > 0)
                          ? 'No Token'
                          : mediatorAllowance && mediatorAllowance.toNumber() < 0
                          ? 'Enable First'
                          : 'Bridge'
                      }
                    />
                  )}
                  {swapErrorMessage ? <SwapCallbackError error={swapErrorMessage} /> : null}
                  {swapSuccess ? <BridgeLinkFooter currencies={[currencies.INPUT, currencies.OUTPUT]} /> : null}
                </Box>
              </Wrapper>
            </BuyWrapper>
          </AppBody>
        </BuySection>
      </PageWrapper>
      <SwapFooter />
    </>
  )
}
