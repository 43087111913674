import { BigNumber } from 'ethers'
import { useSingleCallResult } from '../state/multicall/hooks'
import { getIMTContract, getInceptionMonstaMediatorContract } from '../utils/contractHelpers'

export function useMediatorAllowance(account): BigNumber | undefined {
  const imtContract = getIMTContract()
  const imtMediatorContract = getInceptionMonstaMediatorContract()
  const allowance = useSingleCallResult(imtContract, 'allowance', [account, imtMediatorContract.address])?.result?.[0]
  return allowance
}
