import React from 'react'
import { Button, useWalletModal } from '@pancakeswap/uikit'
import styled from "styled-components";
import useAuth from 'hooks/useAuth'
import { useTranslation } from 'contexts/Localization'

const ButtonWrapper = styled.div`
  margin-right: 0em;
`;

const ConnectWalletButton = (props) => {
  const { t } = useTranslation()
  const { login, logout } = useAuth()
  const { onPresentConnectModal } = useWalletModal(login, logout)

  return (
    <ButtonWrapper>
      <Button onClick={onPresentConnectModal} {...props}>
        {t('Connect Wallet')}
      </Button>
    </ButtonWrapper>
  )
}

export default ConnectWalletButton
