import React from 'react'
import { Currency } from '@pancakeswap/sdk'
import { Button, Text,InjectedModalProps } from '@pancakeswap/uikit'
import styled from 'styled-components'

interface Props extends InjectedModalProps {
  currencies: (Currency | undefined)[]
}

const DetailsFooter = styled.div`
  padding: 8px 0;
  width: 100%;
  max-width: 400px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  color: ${({ theme }) => theme.colors.text};
  background-color: ${({ theme }) => theme.colors.invertedContrast};
  text-align: center;
  margin-top: 5px;
`

export default function BridgeLinkFooter({ currencies }: { currencies: (Currency | undefined)[] }) {
  return (
    <DetailsFooter>
      <Text>Official Marketplace: https://marketplace.monstainfinite.com/</Text>
      <Button variant="text" onClick={() => window.open('https://marketplace.monstainfinite.com/', '_blank')}>
        Click Here To Redirect
      </Button>
    </DetailsFooter>
  )
}
