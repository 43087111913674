import React from 'react'
import styled from 'styled-components'
import { Text, Flex } from '@pancakeswap/uikit'

interface Props {
  tenPercentToken: string
  ninetyPerceentToken: string
}

const VestingBodyContainer = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  padding-top: 30px;
  padding-bottom: 50px;
  width: 100%;
  margin: 0 auto;
`
const ItemText = styled(Text)`
  font-size: 16px;
  color: white;
  ${({ theme }) => theme.mediaQueries.ss} {
    font-size: 12px;
  }
  ${({ theme }) => theme.mediaQueries.xxs} {
    font-size: 14px;
  }
  ${({ theme }) => theme.mediaQueries.xs} {
    font-size: 16px;
  }
`

const InfoText = styled(Text)`
  font-size: 16px;
  color: #FFEF00;
  margin-left: 15px;
  ${({ theme }) => theme.mediaQueries.ss} {
    font-size: 12px;
  }
  ${({ theme }) => theme.mediaQueries.xxs} {
    font-size: 14px;
  }
  ${({ theme }) => theme.mediaQueries.xxs} {
    font-size: 16px;
  }
`

const VestingBody: React.FC<Props> = ({ tenPercentToken, ninetyPerceentToken }) => {
  return (
    <VestingBodyContainer>
      <Flex alignItems="center" flexDirection="column" style={{width: '100%'}}>
        <Flex justifyContent="center" style={{width: '100%', textAlign: 'center'}}>
          <ItemText>
            16 Sep 2021 unlock 10% /
          </ItemText>
          <InfoText>
            { tenPercentToken } $MONI
          </InfoText>
        </Flex>
  
        <Flex justifyContent="center" style={{width: '100%', textAlign: 'center'}}>
          <ItemText>
            16 Nov 2021 unlock 90% /
          </ItemText>
          <InfoText>
            { ninetyPerceentToken } $MONI
          </InfoText>
        </Flex>
      </Flex>
    </VestingBodyContainer>
  )
}

export default VestingBody
